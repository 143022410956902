import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useRef } from 'react'
import Logo from '/newLogo.png'
import { AdminMenus } from './Config'
import userDPimg from '../../assets/useProfileDP.jpg'
import { useUser } from '../../UserContext'
import { useSidebar } from '../../utils/SidebarContext'

export default function Navbar() {
  const { user } = useUser()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { sideBarOpen, toggleSidebar } = useSidebar()
  const dropdownRef = useRef(null)
  const showDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  useEffect(() => {
    function handleClickOutside(e) {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.addEventListener('mousedown', handleClickOutside)
    }
  })
  // show dropdown end =====================================

  return (
    <div className='header'>
      <header className='header-container '>
        <div style={{ display: 'flex', gap: 20 }}>
          {user.token && window.location.pathname !== '/' && (
            <div className='sidebarMenuBtn' onClick={toggleSidebar}>
              <i className={`${sideBarOpen ? 'bi bi-x' : 'bi bi-list'}`}></i>
            </div>
          )}

          <Link to='/'>
            <img className='logo' src={Logo} alt='logo' />
          </Link>
        </div>

        <div className='NavMenus'>
          <h5>Admin</h5>
          <div className='NavProfileButton'>
            <div className='loggedinDropdown' onClick={showDropdown}>
              <img
                src={user.picture || userDPimg}
                alt={user.displayName}
                style={{
                  color: 'white',
                  borderRadius: '50%',
                  width: '40px',
                  height: 'auto',
                }}
              />
              {isDropdownOpen && (
                <div ref={dropdownRef} className='dropdown'>
                  <li className='profileTitle'>
                    <p className='oneLineText3dot'>
                      <b> {user.displayName} </b>
                    </p>
                    <p className='oneLineText3dot'>{user.email}</p>
                  </li>
                  {AdminMenus.map((item) => {
                    return (
                      <Link to={`/${item.link}`} key={item.id}>
                        <li className='list'>
                          <i className={item.icon}></i>
                          {item.name}
                        </li>
                      </Link>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}
