export const AdminMenus = [
  {
    id: 1,
    name: 'Dashboard',
    icon: 'bi bi-grid-1x2',
    link: '/admin',
  },
  {
    id: 2,
    name: 'Leagues',
    icon: 'bi bi-grid-1x2',
    link: '/admin-league',
  },
  {
    id: 3,
    name: 'Game Rooms',
    icon: 'bi bi-grid-1x2',
    link: '/admin-create-game-room',
    subItem: [
      {
        id: 31,
        name: 'Create',
        link: '/admin-create-game-room',
      },
      {
        id: 32,
        name: 'List',
        link: '/admin-game-room-list',
      },
    ],
  },
  {
    id: 4,
    name: 'PlayerTransferValue',
    icon: 'bi bi-grid-1x2',
    link: '/player-transfer-value',
  },
  {
    id: 5,
    name: 'Notification',
    icon: 'bi bi-grid-1x2',
    link: '/admin-notification',
  },
  {
    id: 6,
    name: 'Log out',
    icon: 'bi bi-grid-1x2',
    link: '/logout',
  },
]
