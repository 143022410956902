import { lazy } from 'react'
import PlayerTransferValue from './Admin/PointTransfer/PlayerTransferValue.jsx'
import UnsubscribeEmail from './Pages/UnsubscribeEmail.jsx'
import Loading from './Component/Loading.jsx'

// pages
const Home = lazy(() => import('./Home.jsx'))
const SignIn = lazy(() => import('./Pages/SignIn.jsx'))
const SignUp = lazy(() => import('./Pages/SignUp.jsx'))
const ForgotPassword = lazy(() => import('./Pages/ForgotPassword.jsx'))
const Logout = lazy(() => import('./Pages/Logout.jsx'))
const ChangePassword = lazy(() => import('./Pages/ChangePassword.jsx'))
const ChangePasswordOTP = lazy(() => import('./Pages/ChangePasswordOTP.jsx'))
const EmailVerification = lazy(() => import('./Pages/EmailVerification.jsx'))
const AliasCodeHandler = lazy(() => import('./Pages/AliasCodeHandler.jsx'))

const Dashboard = lazy(() => import('./Dashboard/Dashboard.jsx'))
const Profile = lazy(() => import('./Profile/Profile.jsx'))
const Refer = lazy(() => import('./Refer/Refer.jsx'))
const GlobalLeaderBoard = lazy(
  () => import('./GlobalLeaderBoard/GlobalLeaderBoard.jsx')
)

// gameroom
const AllLeagues = lazy(() => import('./GameRoom/AllLeagues.jsx'))
const GameRooms = lazy(() => import('./GameRoom/GameRooms.jsx'))
const GRDetails = lazy(() => import('./GameRoom/GRDetails.jsx'))
const JoinedGames = lazy(() => import('./JoinedGames/JoinedGames.jsx'))

// team
const MyTeams = lazy(() => import('./MyTeams/MyTeams.jsx'))
const CreateTeam = lazy(() => import('./MyTeams/CreateTeam.jsx'))
const Wallet = lazy(() => import('./wallet/Wallet.jsx'))

// admin
const AdminDashboard = lazy(() => import('./Admin/AdminDashboard.jsx'))
const AdminLeague = lazy(() => import('./Admin/AdminLeague/AdminLeague.jsx'))
const AdminNotification = lazy(
  () => import('./Admin/AdminNotification/AdminNotification.jsx')
)
const CreateGR = lazy(() => import('./Admin/AdminGameRoom/CreateGR.jsx'))
const GRList = lazy(() => import('./Admin/AdminGameRoom/GRList.jsx'))

export const Routings = [
  {
    id: 1,
    path: '/loading',
    element: 'Loading',
    userType: 'public',
    importFrom: Loading,
  },
  {
    id: 2,
    path: '/',
    element: 'Home',
    userType: 'public',
    importFrom: Home,
  },
  {
    id: 3,
    path: '/sign-in',
    element: 'SignIn',
    userType: 'public',
    importFrom: SignIn,
  },
  {
    id: 4,
    path: '/sign-up',
    element: 'SignUp',
    userType: 'public',
    importFrom: SignUp,
  },
  {
    id: 5,
    path: '/forgot-password',
    element: 'ForgotPassword',
    userType: 'public',
    importFrom: ForgotPassword,
  },
  {
    id: 6,
    path: '/change-password',
    element: 'ChangePassword',
    userType: 'public',
    importFrom: ChangePassword,
  },
  {
    id: 7,
    path: '/logout',
    element: 'Logout',
    userType: 'public',
    importFrom: Logout,
  },
  {
    id: 9,
    path: '/change-passwordOTP',
    element: 'ChangePasswordOTP',
    userType: 'public',
    importFrom: ChangePasswordOTP,
  },
  {
    id: 10,
    path: '/email-verification',
    element: 'EmailVerification',
    userType: 'public',
    importFrom: EmailVerification,
  },
  {
    id: 11,
    path: '/leagues',
    element: 'AllLeagues',
    userType: 'public',
    importFrom: AllLeagues,
  },
  {
    id: 12,
    path: '/game-rooms',
    element: 'GameRooms',
    userType: 'public',
    importFrom: GameRooms,
  },
  {
    id: 14,
    path: '/wallet',
    element: 'Wallet',
    userType: 'user',
    importFrom: Wallet,
    isProtected: true,
  },
  {
    id: 15,
    path: '/my-teams',
    element: 'MyTeams',
    userType: 'user',
    importFrom: MyTeams,
  },
  {
    id: 16,
    path: '/create-team',
    element: 'CreateTeam',
    userType: 'user',
    importFrom: CreateTeam,
  },
  {
    id: 17,
    path: '/admin',
    element: 'AdminDashboard',
    userType: 'admin',
    importFrom: AdminDashboard,
    isProtected: true,
  },
  {
    id: 18,
    path: '/admin-league',
    element: 'AdminLeague',
    userType: 'admin',
    importFrom: AdminLeague,
    isProtected: true,
  },
  {
    id: 20,
    path: '/game-rooms/:id',
    element: 'GRDetails',
    userType: 'user',
    importFrom: GRDetails,
  },
  {
    id: 21,
    path: '/joined-games',
    element: 'JoinedGames',
    userType: 'user',
    importFrom: JoinedGames,
    isProtected: true,
  },
  {
    id: 22,
    path: '/dashboard',
    element: 'Dashboard',
    userType: 'user',
    importFrom: Dashboard,
    isProtected: true,
  },
  {
    id: 23,
    path: '/player-transfer-value',
    element: 'PlayerTransferValue',
    userType: 'admin',
    importFrom: PlayerTransferValue,
    isProtected: true,
  },
  {
    id: 24,
    path: '/profile',
    element: 'Profile',
    userType: 'user',
    importFrom: Profile,
    isProtected: true,
  },
  {
    id: 25,
    path: '/refer',
    element: 'Refer',
    userType: 'user',
    importFrom: Refer,
    isProtected: true,
  },
  {
    id: 26,
    path: '/global-leader-board',
    element: 'GlobalLeaderBoard',
    userType: 'user',
    importFrom: GlobalLeaderBoard,
    isProtected: false,
  },
  {
    id: 27,
    path: '/unsubscribe',
    element: 'UnsubscribeEmail',
    userType: 'public',
    importFrom: UnsubscribeEmail,
    isProtected: false,
  },
  {
    id: 28,
    path: '/admin-notification',
    element: 'AdminNotification',
    userType: 'admin',
    importFrom: AdminNotification,
    isProtected: true,
  },
  {
    id: 29,
    path: '/pfc/:aliasCode',
    element: 'AliasCodeHandler',
    userType: 'public',
    importFrom: AliasCodeHandler,
    isProtected: false,
  },
  {
    id: 30,
    path: '/admin-create-game-room',
    element: 'CreateGR',
    userType: 'admin',
    importFrom: CreateGR,
    isProtected: true,
  },
  {
    id: 31,
    path: '/admin-game-room-list',
    element: 'GRList',
    userType: 'admin',
    importFrom: GRList,
    isProtected: true,
  },
]
