import { Link, useLocation } from 'react-router-dom'
import { useRef } from 'react'
import { AdminMenus } from './Config'
import { useSidebar } from '../../utils/SidebarContext'

export default function Sidebar() {
  const sidebarRef = useRef(null)
  const { sideBarOpen, toggleSidebar } = useSidebar()
  const location = useLocation()
  return (
    <>
      <div
        className={`sidebar ${sideBarOpen ? 'show' : ''}`}
        ref={sidebarRef}
        style={{ backgroundColor: 'black' }}
      >
        <div>
          {/* <h5 style={{ marginTop: "10px" }}>Admin</h5> */}
          {AdminMenus.map((item) => {
            const isActive = location.pathname === item.link // Check if active

            if (item.subItem) {
              return (
                <div key={item.link}>
                  <Link to={item.link} onClick={toggleSidebar}>
                    <div
                      className={`sidebarBtn shine ${isActive ? 'active' : ''}`}
                    >
                      <i className={item.icon}></i> {item.name}
                    </div>
                  </Link>
                  <ul className='subItems' style={{ marginLeft: 55 }}>
                    {item.subItem.map((sItem) => {
                      return (
                        <li key={sItem.link}>
                          <Link to={sItem.link} onClick={toggleSidebar}>
                            <div className='sidebarBtn shine '>
                              {sItem.name}
                            </div>
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            } else {
              return (
                <Link key={item.link} to={item.link} onClick={toggleSidebar}>
                  <div
                    className={`sidebarBtn shine ${isActive ? 'active' : ''}`}
                  >
                    <i className={item.icon}></i> {item.name}
                  </div>
                </Link>
              )
            }
          })}
        </div>
      </div>
    </>
  )
}
