import './PrivacyPolicy.css'

function TermOfService() {
  return (
    <div className='privacy-policy'>
      <h1>Terms of Service</h1>
      <h2>1. Introduction</h2>
      <p>
        Welcome to Panenka FC Pte. Ltd. (&quot;we&quot;, &quot;our&quot;,
        &quot;us&quot;). By accessing or using our Platform, you agree to be
        bound by these Terms of Service (&quot;Terms&quot;). If you do not agree
        with any part of these Terms, you must not use our Platform. YOUR
        PARTICIPATION IN THE &apos;Panenka FC Pte. Ltd.&apos; IS ENTIRELY
        VOLUNTARY. IF YOU ARE PARTICIPATING, YOU MUST STRICTLY ADHERE TO THESE
        TERMS.
      </p>
      <h2>2. The Platform</h2>
      <p>
        Panenka FC, the &quot;Platform&quot;, is a gaming software that utilizes
        distributed ledger technologies such as smart contracts and
        zero-knowledge proofs to automate and decentralize game management. By
        using the Platform, you acknowledge and agree to the use of these
        technologies and the inherent risks associated with them.
      </p>
      <h2>3. User Information for Marketing Purposes</h2>
      <p>We collect the following user information for marketing purposes:</p>
      <ul>
        <li>Email</li>
        <li>Wallet Addresses</li>
      </ul>
      <h2>4. User Representations</h2>
      <p>By using our platform, you represent and warrant that:</p>
      <ul>
        <li>
          The registration information you submit will be true, accurate,
          current, and complete;
        </li>
        <li>
          You will maintain the accuracy of such information and promptly update
          such registration information as necessary;
        </li>
        <li>
          You have the legal capacity and you agree to comply with these Terms
          of Use;
        </li>
        <li>You are not a minor in the jurisdiction in which you reside;</li>
        <li>
          You will not access the Platform through automated or non-human means,
          whether through a bot, script, or otherwise;
        </li>
        <li>
          You will not use the Platform for any illegal or unauthorized purpose;
          and
        </li>
        <li>
          Your use of the Platform will not violate any applicable law or
          regulation.
        </li>
      </ul>
      <p>
        The responsibility for using the Concordium wallet and the Panenka FC
        platform lies solely with the end user. Users are required to maintain
        the security of their wallet seed phrases, ensuring they are never
        stored online. Further, users must verify the website link before
        connecting their wallet to the Platform.
      </p>
      <p>
        If you provide any information that is untrue, inaccurate, or
        incomplete, we have the right to suspend or terminate your account and
        refuse any and all current or future use of the Platform (or any portion
        thereof).
      </p>
      <h2>5. Eligibility</h2>
      <p>
        You must be at least 18 years old to use our Platform. By using the
        Platform, you represent and warrant that you meet this age requirement.
      </p>
      <h2>6. Account Registration</h2>
      <p>
        To access certain features of the Platform, you may be required to
        register an account. You agree to provide your email address for the
        creation of your account.
      </p>
      <h2>7. Use of the Platform</h2>
      <p>
        You agree to use the Platform only for lawful purposes and in accordance
        with these Terms. You agree not to use the Platform:
      </p>
      <ul>
        <li>
          In any way that violates any applicable federal, state, local, or
          international law or regulation.
        </li>
        <li>
          For the purpose of exploiting, harming, or attempting to exploit or
          harm minors in any way.
        </li>
        <li>
          With respect to any Activity that seeks to interfere with or
          compromise the integrity, security, or proper functioning of any
          computer, server, network, personal device, or other information
          technology system, including, but not limited to, the deployment of
          viruses and denial of service attacks.
        </li>
        <li>
          To transmit, or procure the sending of, any advertising or promotional
          material, including any &quot;junk mail&quot;, &quot;chain
          letter&quot;, &quot;spam&quot;, or any other similar solicitation.
        </li>
        <li>
          To impersonate or attempt to impersonate Panenka FC Pte. Ltd., a
          Panenka FC Pte. Ltd. employee, another user, or any other person or
          entity.
        </li>
        <li>
          To reproduce, duplicate, copy, sell, resell, or exploit any portion of
          the Platform, including its content and functionality, without express
          written permission from Panenka FC.
        </li>
      </ul>
      <h3>7.1 Gas Fees</h3>
      <p>
        Blockchain transactions require the payment of transaction fees to the
        appropriate network (&quot;Gas Fees&quot;). Except as otherwise
        expressly set forth in the terms of another offer by Panenka FC Pte.
        Ltd., you will be solely responsible to pay the Gas Fees for any
        transaction that you initiate via our Platform.
      </p>
      <h2>8. Intellectual Property Rights</h2>
      <p>
        The Platform and its entire contents, features, and functionality
        (including but not limited to all information, software, text, displays,
        images, video, and audio, and the design, selection, and arrangement
        thereof) are owned by Panenka FC, its licensors, or other providers of
        such material and are protected by Singapore and international
        copyright, trademark, patent, trade secret, and other intellectual
        property or proprietary rights laws.
      </p>
      <h2>9. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by applicable law, in no event will
        Panenka FC, its affiliates, or their licensors, be liable to you or any
        third party for any indirect, consequential, exemplary, incidental,
        special, or punitive damages, including lost profit damages arising from
        or in connection with your use of the Platform, even if Panenka FC has
        been advised of the possibility of such damages. Notwithstanding
        anything to the contrary contained herein, Panenka FC&apos;s liability
        to you for any cause whatsoever and regardless of the form of the
        action, will at all times be limited to the amount paid, if any, by you
        to Panenka FC for the use of the Platform during the term of your use.
      </p>
      <h2>10. Termination</h2>
      <p>
        We may terminate or suspend your access to the Platform immediately,
        without prior notice or liability, for any reason whatsoever, including
        without limitation if you breach these Terms. Upon termination, your
        right to use the Platform will immediately cease. All provisions of
        these Terms which by their nature should survive termination, including,
        without limitation, ownership provisions, warranty disclaimers,
        indemnity, and limitations of liability, will survive termination.
      </p>
      <h2>11. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of Singapore, without regard to its conflict of law provisions.
      </p>
      <h2>12. Changes to Terms</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will provide at least
        30 days notice prior to any new terms taking effect. What constitutes a
        material change will be determined at our sole discretion.
      </p>
      <h2>13. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at &nbsp;
        <a href='mailto:support@panenkafc.gg'>support@panenkafc.gg</a>. By using
        our Platform, you acknowledge that you have read, understood, and agree
        to be bound by these Terms of Service.
      </p>
    </div>
  )
}

export default TermOfService
