import mixpanel from 'mixpanel-browser'
import { isProd } from '../../utils/isProd'

const MIXPANEL_ID = isProd
  ? 'fb1b6a14a6481c33561a88476778c0d0'
  : '580bae4f6c2e56a2e2525bf8339cba04'

if (MIXPANEL_ID) {
  mixpanel.init(MIXPANEL_ID, {
    track_pageview: true,
    // debug: process.env.NODE_ENV === 'development',
  })
} else {
  console.error('Mixpanel token is missing')
}

const trackEvent = (eventName, properties = {}) => {
  if (MIXPANEL_ID) {
    mixpanel.track(eventName, properties)
    // console.log('event :', eventName, properties)
  } else {
    console.error('Mixpanel token is missing, cannot track event')
  }
}

export default trackEvent

export const mixPanelPageTrack = (page) => {
  if (MIXPANEL_ID) {
    mixpanel.track(`Visited :${page}`, {
      page: page,
    })
    // console.log(`Visited ${page}`)
  } else {
    console.error('Mixpanel token is missing, cannot track event')
  }
}

// set mixpanel user properties
export const mixpanelUserProperties = async (userId, properties) => {
  mixpanel.identify(userId)

  const additionalProps = {
    deviceType: navigator.userAgentData
      ? navigator.userAgentData.mobile
        ? 'Mobile'
        : 'Desktop'
      : 'Unknown',
    mobile: navigator.userAgentData
      ? navigator.userAgentData.mobile
      : /Mobi|Android/i.test(navigator.userAgent),
    platform: navigator.platform,
    screenResolution: `${window.screen.width}x${window.screen.height}`,
  }

  if (navigator.userAgentData) {
    const userAgentData = await navigator.userAgentData.getHighEntropyValues([
      'platform',
      'platformVersion',
      'architecture',
      'model',
      'uaFullVersion',
    ])
    additionalProps.deviceModel = userAgentData.model || 'Unknown'
    additionalProps.operatingSystem = userAgentData.platform || 'Unknown'
    additionalProps.operatingSystemVersion =
      userAgentData.platformVersion || 'Unknown'
    additionalProps.browser = 'Unknown'
    additionalProps.browserVersion = userAgentData.uaFullVersion || 'Unknown'
  } else {
    const userAgent = navigator.userAgent
    additionalProps.deviceModel = 'Unknown'
    additionalProps.operatingSystem = parseOS(userAgent)
    additionalProps.operatingSystemVersion = 'Unknown'
    additionalProps.browser = parseBrowser(userAgent)
    additionalProps.browserVersion = parseBrowserVersion(
      userAgent,
      additionalProps.browser
    )
  }

  properties = { ...properties, ...additionalProps }

  mixpanel.people.set(properties)
}

const parseOS = (userAgent) => {
  if (userAgent.indexOf('Win') != -1) return 'Windows'
  if (userAgent.indexOf('Android') != -1) return 'Android'
  if (userAgent.indexOf('Linux') != -1) return 'Linux'
  if (userAgent.indexOf('Mac') != -1) return 'MacOS'
  if (userAgent.indexOf('X11') != -1) return 'UNIX'
  return 'Unknown'
}

const parseBrowser = (userAgent) => {
  if (userAgent.includes('Chrome')) return 'Chrome'
  if (userAgent.includes('Firefox')) return 'Firefox'
  if (userAgent.includes('Safari') && !userAgent.includes('Chrome'))
    return 'Safari'
  if (userAgent.includes('Edge')) return 'Edge'
  return 'Unknown'
}

const parseBrowserVersion = (userAgent, browser) => {
  const browserInfo = userAgent.match(new RegExp(browser + '/[\\d.]+')) || []
  return browserInfo[0] ? browserInfo[0].split('/')[1] : 'Unknown'
}
