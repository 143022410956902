import { useEffect } from 'react'

export const useHandleAppTour = (user, setShowAppTour) => {
  useEffect(() => {
    if (!user?.token || !user?.createdAt) return

    const { diffMinutes } = calculateMinuteDifference(user.createdAt)
    const TOUR_TIME_THRESHOLD_MINUTES = 30
    const wasTourClosed = localStorage.getItem('appTourClosed') === 'true'

    if (!wasTourClosed && diffMinutes < TOUR_TIME_THRESHOLD_MINUTES) {
      setShowAppTour(true)
    }
  }, [user?.token, user?.createdAt, setShowAppTour])
}

// Helper function (no longer needs to be a hook)
function calculateMinuteDifference(createdAt) {
  const createdTime = new Date(createdAt)
  const currentTime = new Date()
  const diffMs = currentTime - createdTime
  return { diffMinutes: Math.floor(diffMs / (1000 * 60)) }
}
