import PropTypes from 'prop-types'
import Football from '../assets/football.png'
import netImg from '../assets/goal net.png'
import Navbar from '../Component/Navbar'
import Footer from './Footer'

function ErrorUI({ error }) {
  const isLazLoadingIssue = error?.message?.includes(
    'dynamically imported module'
  )

  const errorText = {
    header: 'Something',
    gradientPart: 'Went wrong',
  }

  if (isLazLoadingIssue) {
    errorText.header = 'A new version of this site'
    errorText.gradientPart = 'is Available'
  }

  return (
    <main>
      <Navbar />
      <section className='notFoundSection'>
        <img src={netImg} alt='' className='netImg' />
        <div className='content'>
          <i className='bi bi-emoji-frown'></i>
          <h1>
            {errorText.header}{' '}
            <span className='blueGradientTxt'>{errorText.gradientPart}</span>
          </h1>
          <p className='greenGradientTxt'>Try reloading the page</p>
          <button onClick={() => window.location.reload()} className='btn'>
            Reload
          </button>
          <img src={Football} alt='' className='footballImg' />
        </div>
      </section>
      <Footer />
    </main>
  )
}

ErrorUI.propTypes = {
  error: PropTypes.object,
}

export default ErrorUI
