import Popup from 'reactjs-popup'
import PropTypes from 'prop-types'

export default function MyPopup(props) {
  const customOverlayStyle = {
    backgroundColor: 'rgba(55, 55, 59, 0.5)',
  }

  return (
    <>
      {/* user success popup */}
      <Popup
        open={props.isPopupOpen}
        closeOnDocumentClick={props.closeOnDocumentClick || false}
        onClose={props.onClose}
        overlayStyle={customOverlayStyle}
        lockScroll={props.lockScroll || false}
        modal={true}
      >
        <section className='my-popup'>
          <div className='my-popup-div my-popup-text'>
            {props.showCloseBtn ? (
              <button className='PopupCloseBtn' onClick={props.onClose}>
                <i className='bi bi-x-square-fill'></i>
              </button>
            ) : (
              ''
            )}
            {props.popupContent}
          </div>
        </section>
      </Popup>
    </>
  )
}

MyPopup.propTypes = {
  popupContent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  showCloseBtn: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  onClose: PropTypes.func,
  lockScroll: PropTypes.bool,
  closeOnDocumentClick: PropTypes.bool,
}
