import './PrivacyPolicy.css'

// renamed function to Policy cause Ad blocker on some browsers where blocking files called PrivacyPolicy
function Policy() {
  return (
    <div className='privacy-policy'>
      <h1>Privacy Policy</h1>
      <p>
        We respect your privacy and are committed to protecting your personal
        data. This application is intended for users accessing our application.
        In this privacy policy, we explain how we collect, use, and process your
        personal information when you use our application.
      </p>
      <h2>1. Information We Collect</h2>
      <p>
        The only information we require is the user&apos;s email ID, which
        serves the purpose of accessing this application.
      </p>
      <h3>1.1 Automatically Collected Information</h3>
      <p>
        When you use our application, we may automatically collect certain
        information about your device and usage of the application. This
        information may include:
      </p>
      <ul>
        <li>Device type</li>
        <li>Operating system</li>
        <li>IP address</li>
        <li>Usage data (e.g., pages visited, time spent on the app)</li>
      </ul>
      <h2>2. Information Submitted to Us</h2>
      <p>
        When you use our services, including accessing our website or the
        associated mobile application, you provide us with your email ID only.
      </p>
      <h3>2.1 Voluntary Information</h3>
      <p>
        You may choose to provide additional information voluntarily, such as
        feedback or support queries.
      </p>
      <h2>3. Purpose of Collection of Information</h2>
      <p>The information is collected during sign-up for account creation.</p>
      <h3>3.1 Use of Information</h3>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li>To provide and maintain our services</li>
        <li>To notify you about changes to our services</li>
        <li>To provide customer support</li>
        <li>To monitor usage of the service</li>
        <li>To detect, prevent, and address technical issues</li>
      </ul>
      <h2>4. Sharing</h2>
      <p>
        We do share your information with our trusted partners or third parties
        who provide us with support services.
      </p>
      <h3>4.1 Third-Party Services</h3>
      <p>
        We may employ third-party companies and individuals to facilitate our
        service, provide the service on our behalf, perform service-related
        tasks, or assist us in analyzing how our service is used.
      </p>
      <h3>4.2 Legal Requirements</h3>
      <p>
        We may disclose your information if required to do so by law or in
        response to valid requests by public authorities (e.g., a court or a
        government agency).
      </p>
      <h2>5. Data Retention</h2>
      <h3>5.1 Retention Period</h3>
      <p>
        We will only store your personal information for as long as we need it
        for the purposes for which it was collected.
      </p>
      <h3>5.2 Compliance</h3>
      <p>
        We retain your information for the period required to meet your requests
        for services on our platform/application and in compliance with
        applicable laws and statutory requirements.
      </p>
      <h2>6. Information Security</h2>
      <h3>6.1 Security Measures</h3>
      <p>
        We take commercially reasonable technical, physical, and organizational
        steps to safeguard any information you provide to us and protect it from
        unauthorized access, loss, misuse, or alteration.
      </p>
      <h3>6.2 Secure Storage</h3>
      <p>All information you provide to us is stored on secure servers.</p>
      <h3>6.3 Limitations</h3>
      <p>
        We take reasonable security precautions; however, no computer system or
        transmission of information can be completely secure or error-free. You
        should not expect that your information will remain private under all
        circumstances. The Company shall not be liable for any damages of any
        kind arising from the use of the Platform, including, but not limited
        to, any indirect, incidental, special, consequential, or punitive
        damages, or any loss of profits or revenues, whether incurred directly
        or indirectly, or any loss of data, use, goodwill, or other intangible
        losses.
      </p>
      <h2>7. Changes to the Privacy Policy</h2>
      <p>
        Any changes to our privacy policy in the future, including necessary
        changes as per applicable law, will be posted on this page. We encourage
        you to review this policy periodically for any updates or changes.
      </p>
      <h2>8. Account Deletion</h2>
      <p>
        Your account will be automatically deleted within a year or in
        accordance with the company&apos;s policy. You may also request account
        deletion by contacting us at
        <a href='mailto:support@panenkafc.gg'>support@panenkafc.gg</a>.
      </p>
      <h2>9. Grievance Redressal</h2>
      <p>
        If you have any complaints or concerns regarding the use, storage,
        deletion, and disclosure of your personal information provided to us,
        you may write to us at
        <a href='mailto:support@panenkafc.gg'>support@panenkafc.gg</a>.
      </p>
      <h2>10. Dispute Resolution</h2>
      <p>
        Any disputes shall be subject to the exclusive jurisdiction of the
        Registered Office of the Company.
      </p>
    </div>
  )
}

export default Policy
