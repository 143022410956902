import MyPopup from './MyPopup'
import { useUser } from '../UserContext'
import { useEffect, useState } from 'react'

// Constants (move to a config file if reused)

const IFRAME_URL =
  'https://app.supademo.com/embed/cm5wb7ia5258e36cgy20km9pq?embed_v=2'

function FirstAppTour() {
  const { showAppTour, setShowAppTour } = useUser()
  const [isLoading, setIsLoading] = useState(true)

  // 🔥 Reset isLoading every time the modal opens
  useEffect(() => {
    if (showAppTour) {
      setIsLoading(true)
    }
  }, [showAppTour])

  const handleClose = () => {
    setShowAppTour(false)
    localStorage.setItem('appTourClosed', 'true')
  }

  return (
    <MyPopup
      isPopupOpen={showAppTour}
      showCloseBtn={true}
      onClose={handleClose}
      popupContent={
        <>
          <div
            style={{
              position: 'relative',
              boxSizing: 'content-box',
              maxHeight: '80svh',
              width: '45vw',
              minWidth: '300px',
              aspectRatio: '1.8514285714285714',
              padding: '40px 0 40px 0',
            }}
          >
            {isLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
                Loading...
              </div>
            )}

            <iframe
              src={IFRAME_URL}
              loading='lazy'
              title='Panenkafc Demo'
              allow='clipboard-write'
              frameBorder={0}
              webkitallowfullscreen='true'
              mozallowfullscreen='true'
              allowFullScreen
              onLoad={() => setIsLoading(false)}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          </div>

          <button
            onClick={handleClose}
            className='btn'
            style={{ marginInline: 'auto' }}
          >
            Close
          </button>
        </>
      }
    />
  )
}

export default FirstAppTour
