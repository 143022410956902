import { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types' // Import PropTypes

const SidebarContext = createContext()

export const SidebarProvider = ({ children }) => {
  const [sideBarOpen, setSidebarOpen] = useState(false)

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev)
  }

  return (
    <SidebarContext.Provider value={{ sideBarOpen, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  )
}

// ✅ Add PropTypes validation
SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired, // Ensures 'children' is passed and is a valid React node
}

export const useSidebar = () => useContext(SidebarContext)
