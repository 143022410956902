import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Routings } from './Config'
import Loading from './Component/Loading'
import ProtectedRoute from './Component/ProtectedRoute'
import NotFoundPage from './Pages/NotFoundPage'

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {Routings.map((route) => {
          const DynamicComponent = route.importFrom

          return (
            <Route
              key={route.id}
              path={route.path}
              element={
                route.isProtected ? (
                  <ProtectedRoute role={route.userType}>
                    <DynamicComponent />
                  </ProtectedRoute>
                ) : (
                  <DynamicComponent />
                )
              }
            />
          )
        })}
        <Route path='/*' element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
